.skills-container {
    display: flex;
    justify-content: center;
    padding: 1px;
    min-height: 100vh;
    margin-top: 0;
    overflow: hidden;
  }
  
  .content {
    position: relative;
    z-index: 1;
    text-align: center;
    color: #F0F0F0;
    width: 100%;
    padding: 20px;
    max-width: 1000px;
    background-color: transparent;
    overflow: hidden;
  }
  
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    margin-top: 0;
  }
  
  @media (max-width: 1024px) {
    .skills-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .skills-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .skills-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .skill-box {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s, box-shadow 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 250px;
    border: 2px solid #E4A34E;
  }
  
  .skill-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .skill-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 8px;
  }
  
  .skill-box h3 {
    font-size: 18px;
    margin: 5px 0;
    color: rgba(0, 0, 0, 0.9);
  }
  
  .tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 8px;
  }
  
  .tag {
    background-color: #E4A34E;
    color: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    padding: 4px 8px;
    margin: 1px;
    font-size: 12px;
  }
  