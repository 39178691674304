.contact-container {
  padding: 20px; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: #F0F0F0;
}

.section-background {
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
}

.icon {
  width: 30px;
  height: 30px;
  transition: transform 0.2s, filter 0.2s;
}

.icon:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}
