.section {
    position: relative; 
    min-height: 200px; 
    max-height: 400px;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    overflow: hidden;
    padding: 20px;
    color: #F0F0F0;
    border-radius: 10px;
    margin: 20px auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.section-background {
    background: rgba(0, 0, 0, 0.6);
}


.experience-education-container {
    padding: 20px; 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    max-width: 900px; 
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; 
    overflow: auto;
}

h1 {
    margin-bottom: 10px;
    color: #E4A34E
}

.section h1 {
    margin-bottom: 10px;
    color: #E4A34E; 
}

.top-right-icon {
    position: absolute; 
    top: 10px; 
    right: 10px; 
    width: 40px; 
    height: 40px; 
}

.section-icon {
    width: 40px; 
    height: 40px; 
    margin-left: 10px; 
    position: absolute;
    top: 10px; 
    right: 10px; 
}

.experience-item,
.education-item {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.experience-item p {
    font-size: 1.2em;
    margin-bottom: 1em;
    color: #F0F0F0;
    text-align: center;
    width: 100%;
}

.education-list {
    list-style: none; 
    padding-left: 0; 
    color: #F0F0F0; 
}

.education-list li {
    margin-bottom: 5px;
    font-size: 14px;
}

.degree-icon {
    width: 30px; 
    height: 30px; 
    margin-left: 20px;
    vertical-align: middle; 
}

.experience-link,
.more-info {
    color: #E4A34E; 
    text-decoration: none; 
}

.experience-link:hover,
.more-info:hover {
    text-decoration: underline; 
}

.university-item {
    text-align: center;
    margin-top: 20px;
}

.more-info {
    color: #E4A34E; 
    display: block;
    margin-top: 10px;
}
