* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  font-family: Arial, sans-serif;
  background-color: #F5F5DC;
  color: #56564E;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  border: 5px solid #E4A34E;;
}

main {
  flex: 1;
  position: relative;
  background-color: #F5F5DC; 
}


.canvas-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}