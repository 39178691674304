/* src/components/css/Posts.css */

.posts-container {
  position: relative;
  min-height: 100vh; 
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: transparent;
  padding: 20px;
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: #333;
  padding: 10px;
  width: 100%;
  max-width: 1200px;
}

.intro {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  color: #F0F0F0; 
  margin-bottom: 20px;
}

.intro h2 {
  font-size: 24px;
}

.posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 15px;
  margin-top: 15px;
  justify-content: center;
}

.post-box {
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid #E4A34E;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 220px;
  max-width: 100%;
  cursor: pointer;
}

.posts-grid > .post-box:only-child {
  justify-self: center;
  grid-column: 1 / -1; 
  max-width: 200px;
}




.post-box {
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid #E4A34E;
  border-radius: 8px; 
  padding: 15px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  cursor: pointer;
}


.post-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.post-image {
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
}

.post-box h3 {
  font-size: 20px;
  margin: 5px 0;
  color: black;
}

.post-box p {
  font-size: 14px;
  margin-bottom: 5px;
  color: rgb(38, 38, 38);
}

.tags {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
}

.tag {
  background-color: #E4A34E;
  color: white;
  border-radius: 5px; 
  padding: 5px 10px;
  margin: 2px;
  font-size: 12px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .intro h2 {
    font-size: 18px;
  }

  .post-box h3 {
    font-size: 18px;
  }

  .post-box p {
    font-size: 12px;
  }

  .tag {
    font-size: 10px;
  }
}
